<template>
  <div class="home">
    <t-header />
     <div class="price-page">
      帮助
  </div>
    <t-footer />
  </div>
</template>

<script>
import tHeader from '@/components/tHeader/index.vue'
import tFooter from '@/components/tFooter/index.vue'
export default {
  name: "help",
  components: {
    tHeader,
    tFooter
  },
  methods: {
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.price-page {
    color: #000;
    font-size: 64px;
    text-align: center;
}
</style>
