<template>
  <div class="footer-container">
    <div class="footer-box">
      <div class="footer-left">
        <img class="logo" v-lazy="logo" />
        <ul>
          <li>
            <a href="/#/privacyAgreement">隐私协议</a>
          </li>
          <li>
            <a href="/#/serviceAgreement">用户协议</a>
          </li>
          <li>
            <a href="/#/about">关于我们</a>
          </li>
        </ul>
      </div>
      <div class="footer-right">
        <!--  <ul class="imgs-box">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul> -->
        <div class="info">
          <p>建造你的场景</p>
          <p>建造虚拟化身</p>
          <p>免费的元宇宙场景</p>
        </div>
      </div>
    </div>
    <div class="foot_flex2">
      <div class="list">
        <div class="text">版权所有© 元宇未来科技（北京）有限公司</div>
      </div>
      <!-- <div class="list">
        <div class="text">京公网安备 11010502043612号</div>
      </div> -->
      <div class="list"><a target="_blank" href="https://beian.miit.gov.cn/" style="color: rgb(229, 229, 231);">京ICP备2022005728号-3</a></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "tFooter",
  components: {},
  methods: {},
  data() {
    return {
      logo: require("../../assets/images/logo.png"),
    };
  },
  created() {},
};

</script>
<style scoped lang="scss">
.footer-container {
  width: 100%;
  margin-top: 183px;
  background-color: #000;

  .foot_flex2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 400;
    color: #7C7E96;
    padding-bottom:30px;

    .list {
      margin-right: 1rem;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .footer-box {
    padding: 150px 224px 187px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #FFFFFF;
    margin-bottom: 30px;

    .footer-left {
      .logo {
        width: 210px;
        margin-bottom: 86px;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        width: 500px;

        li {
          width: 100%;
          // max-width: 100px;
          display: flex;
          flex-wrap: nowrap;

          a {
            color: #FFFFFF;
            font-size: 24px;
            margin-bottom: 60px;
            // margin-right: 84px;
            display: block;
            width: 160px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .footer-right {
      .imgs-box {
        display: flex;
        justify-content: space-between;
        width: 300px;

        li {
          width: 55px;
          height: 55px;
          background-color: #fff;
          border-radius: 6px;
        }
      }

      .info {
        p {
          text-align: right;
          margin-top: 60px;
          font-size: 24px;
        }
      }
    }
  }
}

</style>
