<template>
  <div class="header-container" :class="{'barFixed': isFixed}">
    <router-link to="home" class="logo-left">
      <img v-lazy="logo" alt="logo"/>
    </router-link>
    <div class="header-right">
      <ul class="header-box">
        <!-- <li @click="jumpPage('metaApp')">元应用</li>
        <li @click="jumpPage('price')">价格</li> -->
        <li @click="wxLoginBtn()">编辑器</li>
      </ul>
      <!-- 未登录 -->
      <div v-if="!avatar" class="login-btn" @click="loginBtn">登录</div>
      <!-- 已登录 -->
      <div class="avatar" v-else @click="jumpPage('square')">
        <img v-lazy="avatar"  />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "tHeader",
  components: {},
  computed: {
    ...mapGetters([
      'avatar',
      'name'
    ])
  },
  data() {
    return {
      // isLogin: false,
      isFixed: false,
      offsetTop: 92,
      logo: require("../../assets/images/logo.png"),
      // avatar: require("../../assets/images/avatar.png"),
    };
  },
  methods: {
    // 登录
    loginBtn() {
      this.$router.push({ path: '/login'})
    },
    wxLoginBtn() {
      window.open(`https://app.metaio.cc/spoke`);
      // this.$router.push({ path: "/wxLogin" });
    },
    // 滚动监听 滚动触发的效果写在这里
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (scrollTop >= this.offsetTop) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
    jumpPage(text) {
      switch (text) {
        case 'metaApp':
          this.$router.push({ path: '/metaApp'})
          break;
        case 'price':
          this.$router.push({ path: '/price'})
          break;
        case 'help':
          this.$router.push({ path: '/help'})
          break;
        case 'user':
          this.$router.push({ path: '/user'})
          break;
        case 'square':
          this.$router.push({ path: '/square'})
          break;
        default:
          break;
      }
    }
  },
  mounted() {
    // 开启滚动监听
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll); // 离开页面 关闭监听 不然会报错
  }
};
</script>

<style scoped lang="scss">
@keyframes fadeInFn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header-container {
  background-color: #000;
  height: 92px;/*no*/
  padding: 0 122px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  &.barFixed {
    width: calc(100% - 244px);
    position: fixed;
    top: 0;
    left: 0;
    animation: fadeInFn 2s linear;
  }
  .logo-left {
    width: 202px;
    img {
      width: 100%;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    .header-box {
      display: flex;
      font-size: 18px;
      margin-right: 84px;
      li {
        margin-left: 84px;
        cursor: pointer;
      }
    }
    .login {
      &-btn {
        width: 76px;/*no*/
        height: 43px;/*no*/
        text-align: center;
        line-height: 43px;/*no*/
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(20px);
        border-radius: 60px;
        cursor: pointer;
      }
    }
    .avatar {
      text-align: center;
      width: 67px;
      height: 67px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      cursor: pointer;
      img {
        width: 70%;
        height: 100%;
      }
    }
  }
}
</style>
